.live-location-container {
    display: flex;
}

.live-location-container .location {
    /* border: 1px solid red; */
    overflow: hidden;
    margin-right: 12px;
}

.live-location-container .location img {
    aspect-ratio: 2/3;
    object-fit: cover;
}

.live-location-container .order-detail {
    /* border: 1px solid green; */
    margin-left: 12px;
}

.live-location-container .order-detail .order-detail-header h1 {
    font-size: 20px;
    font-weight: 700;
}

.live-location-container .order-detail .order-detail-header h3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--secondary-color);
}

.live-location-container .order-detail .order-status {
    padding: 16px;
    background-color: var(--buttons-background-color);
    gap: 16px;
    margin-top: 24px;
    border-radius: 12px;
}

.live-location-container .order-detail .order-status .gift-pack-svg {
    padding: 10px;
    background-color: var(--secondary-color);
    border-radius: 5px;
}

.live-location-container .order-detail .order-status h3 {
    font-size: 20px;
    font-weight: 700;
}

.live-location-container .order-detail .order-status p {
    font-size: 14px;
    font-weight: 400;
}

.delivery-svg {
    gap: 4px;
    padding: 12px;
    background-color: var(--secondary-color);
    border-radius: 5px;
}

.live-location-container .order-detail .delivery-status {
    display: flex;
    margin-top: 24px;
}

.live-location-container .order-detail .delivery-status .delivery-header {
    font-size: 16px;
    font-weight: 700;
}

.live-location-container .order-detail .delivery-status .delivery-detail p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.live-location-container .order-detail .delivery-status .delivery-detail h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.live-location-container .order-detail .partner-detail {
    margin-top: 24px;
}

.live-location-container .order-detail .partner-detail p {
    font-size: 16px;
    font-weight: 700;
}

.live-location-container .order-detail .partner-detail .partner-container {
    padding: 12px;
    background-color: var(--buttons-background-color);
    gap: 16px;
    align-items: center !important;
    /* border-radius: 12px; */
}

.live-location-container .order-detail .partner-detail .partner-container .avatar-img {
    height: 60px;
    width: 60px;
}

.live-location-container .order-detail .partner-detail .partner-container .avatar-img img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
}

.live-location-container .order-detail .partner-detail .partner-container .partner-prsnl-detail h2 {
    font-size: 16px;
    font-weight: 700;
}

.live-location-container .order-detail .partner-detail .partner-container .partner-prsnl-detail p {
    font-size: 14px;
    font-weight: 400;

}

.partner-phone-svg {
    padding: 8px;
    background-color: #29363F;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.overlay-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.overlay-content p {
    margin: 0;
    color: #dc3545;
    font-weight: 500;
}