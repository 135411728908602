#all-sellers .seller_section_header {
  margin-bottom: 12px;
  /* background-color: var(--second-cards-color); */
  /* padding: 15px; */
  border-radius: 5px;
}

#all-sellers .seller_section_header p {
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
  letter-spacing: 2px;
  font-weight: 700 !important;
  margin: 0;
}

#all-sellers .seller_section_header a {
  font-size: 14px;
  font-weight: 400 !important;
  letter-spacing: 1px;
  color: var(--secondary-color);
  text-decoration: none;
  margin-left: 10px;
}

#all-sellers .seller_section_header .seller_button {
  border-radius: 8px;
  font-size: 14px;
  /* padding: 8px 16px; */
  padding: 7px;
  text-decoration: none;
  background-color: var(--secondary-color);
  text-align: center;
  color: var(--text-field-color) !important;
}

#all-sellers .seller_section_header .prev-arrow-seller {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #888 !important;
  top: 0;
  right: 5%;
  padding: 8px;
  background: var(--second-cards-color);
  box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
  font-size: 15px;
  z-index: 10;
  opacity: 0.7 !important;
  visibility: visible;
  border-radius: 100%;
  transition: all 300ms linear;
}

#all-sellers .seller_section_header .next-arrow-seller {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #888 !important;
  top: 0;
  right: 0;
  padding: 8px;
  background: var(--second-cards-color);
  box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
  font-size: 15px;
  z-index: 10;
  opacity: 0.7 !important;
  visibility: visible;
  border-radius: 100%;
  transition: all 300ms linear;
}

#all-sellers .seller_section_header .prev-arrow-seller>svg {
  fill: var(--font-color) !important;
}

#all-sellers .seller_section_header .next-arrow-seller>svg {
  fill: var(--font-color) !important;
}

[dir="rtl"] #all-sellers .seller_section_header .see_sellers_arrow {
  transform: rotate(180deg);
}

[dir="rtl"] #all-sellers .seller_section_header .next-arrow-seller {
  order: 0;
}

[dir="rtl"] #all-sellers .seller_section_header .prev-arrow-seller {
  order: 1;
}

.allSellersContainer .cover {
  height: 200px;
}

.allSellersContainer .cover img {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
  z-index: -1;
  background-size: cover;
  background-position: center center;
}

.allSellersContainer .cover .page-heading {
  position: relative;
  bottom: 100px;
  text-align: center;
}

.allSellersContainer .cover .page-heading h5 {
  color: var(--text-field-color);
  font-size: 3rem;
}

.allSellersContainer .cover .page-heading p {
  color: var(--text-field-color);
  font-size: 2rem;
}

.allSellersContainer .cover .page-heading p span {
  color: var(--secondary-color);
}

/* .allSellersContainer .content .card {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.allSellersContainer .content .card {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 18px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.allSellersContainer.home .content .card {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  /* margin: 5px; */
  transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
  padding: 16px;
}

.allSellersContainer.home .content .card:hover {
  color: #fff !important;
  box-shadow: inset 330px 0 0 0 var(--secondary-color) !important;
}

.allSellersContainer .content .card img {
  width: 80px;
  height: 80px;
  /* height: 26rem; */
  /* border-radius: 8px 8px 0px 0px; */
  aspect-ratio: 80 / 80;
  object-fit: cover;
  /* margin: 16px; */
  border-radius: 4px;
}

.allSellersContainer .content .card p {
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  margin-left: 16px;
}



.allSellersContainer .content .card .card-body {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.allSellersContainer .content .card:hover {
  outline: var(--secondary-color);
  transform: none !important;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color) !important;
}

.allSellersContainer .content .card .card-body:hover p {
  color: var(--secondary-color) !important;
}

.allSellersContainer .content .card:hover .card-body p {
  color: var(--secondary-color) !important;
}

.allSellersContainer .content .card .card-body p {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  display: block;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.allSellersContainer .slick-track {
  margin-left: unset !important;
  margin-right: unset !important;
}

.allSellersContainer .sellerListingPagination>a {
  color: var(--font-color);
}