.loader-full-screen {
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  /* filter: brightness(50%); */
  width: 100%;
  height: 100%;
}

.loader-box {
  padding: 20px;
  width: 100px;
  height: 100px;
  background: #3b2f2f8f;
  /* filter: brightness(100%); */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 1;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--loader-bg);
}

.loader {
  opacity: 1;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* border-left: 4px solid #FF3D00; */
  border-left: 4px solid var(--secondary-color);
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 30rem) {
  .loader {
    width: 35px;
    height: 35px;
  }

  .loader::after {
    width: 35px;
    height: 35px;
  }
}

.ant-modal .ant-modal-close {
  position: absolute;
  width: 0px;
  height: 0px;
  visibility: hidden;
}
