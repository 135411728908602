.container-class {
  background-color: #000000bf;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -44.5%);
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  min-height: 500px;
}
