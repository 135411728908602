.transaction-list {
  overflow: auto;
}
.transaction-list .transaction-list-table {
  width: 100%;
  margin-top: 20px;
  background-color: var(--second-cards-color);
  border-radius: 8px;
}

.transaction-list .transaction-list-table thead {
  background-color: var(--secondary-color);
}

.transaction-list .transaction-list-table thead tr th:first-child,
.transaction-list .transaction-list-table tbody tr th:first-child,
.transaction-list .transaction-list-table thead tr th:last-child,
.transaction-list .transaction-list-table tbody tr th:last-child {
  /* padding: 5px 10px; */
}

.transaction-list .transaction-list-table thead tr th {
  color: #fff;
  padding: 5px;
}

.transaction-list .transaction-list-table thead tr th:first-child {
  border-top-left-radius: 8px;
}

.transaction-list .transaction-list-table thead tr th:last-child {
  border-top-right-radius: 8px;
}

.transaction-list .transaction-list-table tbody tr.last-column th:first-child {
  border-bottom-left-radius: 8px;
}

.transaction-list .transaction-list-table tbody tr.last-column th:last-child {
  border-bottom-right-radius: 8px;
}

.transaction-list .transaction-list-table tbody tr {
  border-bottom: 1px solid #ddd;
  color: var(--font-color);
}

.transaction-list .transaction-list-table tbody tr:last-child {
  border: none;
}

.transaction-list .transaction-list-table tbody tr th {
  padding: 5px;
  font-weight: 500;
}

.transaction-list .transaction-list-table tbody tr th.amount {
  color: var(--secondary-color);
  font-weight: bold;
}

.transaction-list .transaction-list-table tbody tr th.failed p {
  border: 1px solid var(--bs-danger);
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.21);
  text-align: center;
}

.transaction-list .transaction-list-table tbody tr th.success p {
  border: 1px solid rgb(14, 170, 14);
  border-radius: 5px;
  background-color: rgba(19, 203, 19, 0.21);
  text-align: center;
}

.no-transaction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-transaction img {
  width: 30vw;
}

.no-transaction p {
  font-size: 2.455rem;
  font-weight: bolder;
  margin-top: 20px;
  color: var(--font-color);
}
.transactionItems > a {
  color: var(--font-color) !important;
}
